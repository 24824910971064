import { type PropsWithChildren, useMemo } from 'react'

import { type FilterConfig } from '../../components/CustomFilterForm/types'
import { type SavedFilter } from '../../types/SavedFilter'
import { useAnalyticsParams } from '../analytics/hooks'
import { useBuildFilterLists } from './hooks'
import { allFilter } from './in-process-filters'
import { ShipmentFilterListProvider } from './ShipmentFilterListProvider'
import {
  bookedDateFilter,
  directionFilter,
  interlineFilter,
  pricingMethodFilter,
  pricingTypeFilter,
  shippingModeFilter,
  useAccountFilters,
} from './useFilterConfigs'
import { useHiddenViews, useSavedViews } from './useSavedViews'

export const AnalyticsFiltersProvider = ({ children }: PropsWithChildren) => {
  const { view } = useAnalyticsParams()
  const filterConfig = useAnalyticsFilterConfig()
  const userFilterConfig = useUserAnalyticsFilterConfig()
  const filters = useSavedAnalyticsFilters()
  return (
    <ShipmentFilterListProvider
      state={{
        listType: 'analytics',
        filters,
        canSearch: false,
        filterConfig: view === 'users' ? userFilterConfig : filterConfig,
      }}
    >
      {children}
    </ShipmentFilterListProvider>
  )
}

function useAnalyticsFilterConfig(): FilterConfig[] {
  const {
    carrierFilter,
    quotedByFilter,
    bookedByFilter,
    tagFilter,
    locationFilter,
  } = useAccountFilters()

  return [
    directionFilter,
    shippingModeFilter,
    carrierFilter,
    quotedByFilter,
    bookedByFilter,
    bookedDateFilter,
    interlineFilter,
    pricingMethodFilter,
    pricingTypeFilter,
    tagFilter,
    locationFilter,
  ]
}

function useUserAnalyticsFilterConfig(): FilterConfig[] {
  const { bookedByFilter, tagFilter, locationFilter } = useAccountFilters()

  return [
    bookedByFilter,
    bookedDateFilter,
    interlineFilter,
    pricingMethodFilter,
    pricingTypeFilter,
    tagFilter,
    locationFilter,
  ]
}

export const allLtlFilter: SavedFilter = {
  _id: 'ltl',
  icon: 'truck',
  name: 'LTL',
  fullLabel: 'Less Than Truckload',
  isSystemFilter: true,
  filter: { mode: ['ltl'] },
  isDefault: true,
  isLocked: false,
  queryString: '',
}

export const allTlFilter: SavedFilter = {
  _id: 'tl',
  icon: 'truck-moving',
  name: 'Truckload',
  fullLabel: 'Truckload',
  isSystemFilter: false,
  filter: { mode: ['truckload'] },
  isDefault: true,
  isLocked: false,
  queryString: '',
}

export const allParcelFilter: SavedFilter = {
  _id: 'parcel',
  icon: 'box',
  name: 'Parcel',
  fullLabel: 'Parcel',
  isSystemFilter: false,
  filter: { mode: ['parcel'] },
  isDefault: true,
  isLocked: false,
  queryString: '',
}

function useSavedAnalyticsFilters(): SavedFilter[] {
  const hiddenViews = useHiddenViews()
  const savedViews = useSavedViews('analytics')
  const systemFilters = useBuildFilterLists([
    allLtlFilter,
    allTlFilter,
    allParcelFilter,
  ])

  return useMemo(() => {
    systemFilters.unshift(allFilter)
    return [
      ...systemFilters.filter(
        f => !hiddenViews.find((id: string) => id === f._id),
      ),
      ...savedViews,
    ]
  }, [hiddenViews, savedViews, systemFilters])
}
