import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { usePermissions } from '../../features/auth'
import { HideForVendor } from '../../features/auth/HasRole'
import { useRole } from '../../features/auth/hooks'
import { useShipmentLabels } from '../../features/labels/hooks'
import { useHasUnreadMessages } from '../../features/shipment-list/hooks/useHasUnreadMessages'
import { useArchiveLoads } from '../../hooks/shipments'
import { useBolLinkState } from '../../hooks/shipments/useBolLinkState'
import { composeEditShipmentUrl } from '../../hooks/shipments/useEditBookingPage'
import { useRepeatShipment } from '../../hooks/shipments/useRepeatShipment'
import { routes } from '../../routes'
import { canConfirmShipment } from '../../utils/shipmentFuncs'
import UploadDocumentField from '../inputs/UploadDocumentField'
import { List, ListItem } from '../List'
import { type PanelName } from './LoadDetailPanel'

type Props = {
  setActivePanel: Dispatch<SetStateAction<PanelName>>
  shipment: FullShipment
  showRatesPanel: () => void
}

const ShipmentActions = ({
  setActivePanel,
  shipment,
  showRatesPanel,
}: Props) => {
  const [showUploadBOL, setShowUploadBOL] = useState(false)
  const { canBook } = usePermissions()
  const { status } = shipment ?? {}
  const { archive, canArchive, isArchiving } = useArchiveLoads(status)
  const closeUploadField = useCallback(() => setShowUploadBOL(false), [])
  const labels = useShipmentLabels(shipment)
  const repeat = useRepeatShipment(shipment)
  const role = useRole()
  const hasUnreadMessages = useHasUnreadMessages(shipment.messageSummary)

  const {
    carrierBolUrl,
    fvBolUrl,
    hideFvBolFromSpotQuote,
    canFinishBol,
    isProcessing: isBolProcessing,
  } = useBolLinkState(shipment)

  useEffect(() => {
    setShowUploadBOL(false)
  }, [shipment?.loadId])

  if (!shipment) return null

  const { isArchived, loadId, pickup } = shipment
  const isProcessingPickup = pickup?.status === 'requesting'
  const isAwarded = status === 'awarded'
  const isCanceled = status === 'canceled'
  const isConfirmed = status === 'confirmed'
  const isShipper = role === 'shipper'
  const isBooked = isAwarded || isConfirmed
  const canRetender = isBooked && pickup?.method !== 'api'

  const finishBolLink = composeEditShipmentUrl(shipment, 'listing', 'finishBOL')
  const canSelectCarrier =
    canRetender && canBook && isShipper && !!showRatesPanel
  const canUploadBol = !isCanceled && canBook
  const canUpdateTracking =
    canBook && (isConfirmed || status === 'picked-up' || status === 'delivered')

  return (
    <List>
      {canConfirmShipment(shipment) && canBook && (
        <ListItem>
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              !isProcessingPickup && setActivePanel('confirm')
            }}
          >
            <Icon
              className="fa-fw"
              icon={isProcessingPickup ? 'sync' : 'user'}
              spin={isProcessingPickup}
            />
            <span>
              {isProcessingPickup ? 'Processing pickup' : 'Confirm this myself'}
            </span>
          </a>
        </ListItem>
      )}

      {canSelectCarrier && (
        <ListItem>
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              showRatesPanel()
            }}
            rel="noreferrer"
            target="_blank"
          >
            <Icon className="fa-fw" icon="truck-moving" />
            <span>Select a new carrier</span>
          </a>
        </ListItem>
      )}

      {isBolProcessing && (
        <ListItem>
          <a
            href=""
            onClick={e => e.preventDefault()}
            rel="noreferrer"
            target="_blank"
          >
            <Icon className="fa-fw" icon="sync" />
            <span>Processing BOL</span>
          </a>
        </ListItem>
      )}

      {fvBolUrl && !carrierBolUrl && !hideFvBolFromSpotQuote && (
        <ListItem>
          <FvLinkButton
            href={fvBolUrl}
            icon="file-contract"
            rel="noreferrer"
            target="_blank"
            fw
          >
            View Freightview BOL
          </FvLinkButton>
        </ListItem>
      )}

      {carrierBolUrl && (
        <ListItem>
          <FvLinkButton
            href={carrierBolUrl}
            icon="file-contract"
            rel="noreferrer"
            target="_blank"
            fw
          >
            View Carrier BOL
          </FvLinkButton>
        </ListItem>
      )}

      {canUpdateTracking && (
        <ListItem>
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              setActivePanel('track')
            }}
          >
            <Icon className="fa-fw" icon="thumbtack" transform="down-1" />
            <span>Update tracking status</span>
          </a>
        </ListItem>
      )}

      {canFinishBol && (
        <ListItem>
          <FvLinkButton icon="file-minus" fw to={finishBolLink}>
            Finish Freightview BOL
          </FvLinkButton>
        </ListItem>
      )}

      {canUploadBol && (
        <ListItem>
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              setShowUploadBOL(true)
            }}
          >
            <Icon icon="cloud-upload" className="fa-fw" />
            <span>Upload your own BOL</span>
          </a>

          {showUploadBOL && (
            <div className="mx-0 mb-2 mt-4">
              <UploadDocumentField
                defaultDocType="bol"
                handleClose={closeUploadField}
                loadId={loadId}
                onSuccess={closeUploadField}
              />
            </div>
          )}
        </ListItem>
      )}

      {labels.hasLabels && (
        <ListItem>
          <FvLinkButton
            href={labels.href}
            icon={labels.isLoading ? 'sync' : 'tags'}
            iconClass="fa-fw"
            onClick={() => {
              if (labels.href || labels.isLoading) return
              setActivePanel('label')
            }}
            rel="noreferrer"
            target="_blank"
          >
            Print labels
          </FvLinkButton>
        </ListItem>
      )}

      <HideForVendor>
        <ListItem>
          <FvLinkButton
            fw
            icon="comment-alt-lines"
            onClick={() => setActivePanel('message')}
            className="relative"
          >
            {hasUnreadMessages && <MessageDot />}
            Messages / Notes / Tags
          </FvLinkButton>
        </ListItem>
      </HideForVendor>
      <ListItem>
        <FvLinkButton fw icon="arrow-to-right" to={routes.details(loadId)}>
          More detail / options
        </FvLinkButton>
      </ListItem>

      <HideForVendor>
        <ListItem>
          <FvLinkButton fw icon="clone" onClick={repeat}>
            Repeat shipment
          </FvLinkButton>
        </ListItem>
      </HideForVendor>
      {canArchive && (
        <HideForVendor>
          <ListItem>
            <FvLinkButton
              fw
              icon={isArchiving ? 'spinner' : 'archive'}
              onClick={() => {
                archive({
                  loadIds: [loadId],
                  shouldArchive: !isArchived,
                })
              }}
            >
              {isArchived ? 'Unarchive' : 'Archive'}
            </FvLinkButton>
          </ListItem>
        </HideForVendor>
      )}
    </List>
  )
}

export default ShipmentActions

const MessageDot = () => (
  <div className="bg-fv-orange-bright w-1 h-1 absolute -left-3 top-[.35rem] animate-pulse" />
)
