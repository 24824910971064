import { List, ListItem } from '../../../components/List'
import { HasFeature } from '../../auth'
import { HideForVendor } from '../../auth/HasRole'
import { SettingsSection } from '../SettingsSection'
import { useAppPreferences } from './hooks'
import {
  ltlPreferences,
  parcelPreferences,
  spotQuotePreferences,
} from './preferences'
import TruckloadPreferences from './TruckloadPreferences'
import UserPreferenceToggle from './UserPreferenceToggle'

const UserPreferencesPage = () => {
  const preferences = useAppPreferences()

  return (
    <div className="settings-main">
      <TruckloadPreferences />

      <SettingsSection title="LTL Quoting">
        <List fcp0 lcp0>
          {ltlPreferences.map(p => (
            <ListItem key={p.value}>
              <UserPreferenceToggle
                checked={!!preferences[p.value]}
                preference={p}
              />
            </ListItem>
          ))}
        </List>
      </SettingsSection>

      <HasFeature name="parcel">
        <SettingsSection title="Parcel Quoting">
          <List fcp0 lcp0>
            {parcelPreferences.map(p => (
              <ListItem key={p.value}>
                <UserPreferenceToggle
                  checked={!!preferences[p.value]}
                  preference={p}
                />
              </ListItem>
            ))}
          </List>
        </SettingsSection>
      </HasFeature>

      <HideForVendor>
        <SettingsSection title="Messaging">
          <List fcp0 lcp0>
            {spotQuotePreferences.map(p => (
              <ListItem key={p.value}>
                <UserPreferenceToggle
                  checked={!!preferences[p.value]}
                  preference={p}
                />
              </ListItem>
            ))}
          </List>
        </SettingsSection>
      </HideForVendor>
    </div>
  )
}

export default UserPreferencesPage
