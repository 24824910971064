import union from 'lodash/union'
import { type ParsedQuery } from 'query-string'
import { type MutableRefObject, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { parseQueryString, stringifyQueryString } from '@fv/client-core'
import { type Workflow } from '@fv/models'

import {
  getFilterQueryData,
  isSavedFilterGroup,
  type SavedFilter,
} from '../../types/SavedFilter'
import { useAccountFeatures } from '../auth'
import { useAccountSettings } from '../settings/account-settings/hooks'
import {
  useShipmentFilterListContext,
  useShipmentFilters,
} from './ShipmentFilterListProvider'

export const useFilterControls = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useShipmentFilters()
  const canSearch = useShipmentFilterListContext(s => s.canSearch)
  const viewId = searchParams.get('view')
  const flattenedFilters = filters.flatMap(f =>
    isSavedFilterGroup(f) ? [f, ...f.children] : [f],
  )
  const defaultFilter = flattenedFilters.find(f => f.isDefault) as SavedFilter
  const activeFilter = flattenedFilters.find(f => f._id === viewId)
  const isCustomFilter = useMemo(() => {
    return searchParams.get('view') === 'custom'
  }, [searchParams])

  const setSearch = (search: string) => {
    const queryData = isCustomFilter
      ? parseQueryString(searchParams.toString())
      : getFilterQueryData(activeFilter ?? defaultFilter)

    setSearchParams(
      stringifyQueryString({
        ...queryData,
        search,
      }),
    )
  }

  return {
    isCustomFilter,
    canSearch,
    searchParams,
    setSearch,
    search: searchParams.get('search') ?? '',
    filters,
    flattenedFilters,
    defaultFilter,
    activeFilter,
  }
}

export const useHandleFilterChange = (
  listEl?: MutableRefObject<HTMLDivElement | null>,
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return {
    handle: (data: ParsedQuery) => {
      listEl?.current?.scroll(0, 0)
      setSearchParams(
        stringifyQueryString({
          ...data,
          search: undefined,
        }),
      )
    },
    searchParams,
  }
}

export function usePreferredWorkflows(): Workflow[] {
  const accountSettings = useAccountSettings()
  const { parcel, truckLoad, ltl } = useAccountFeatures()
  const workFlowModes = [ltl, truckLoad, parcel]
  const workflows: Workflow[] = ['ltl', 'truckload', 'parcel']
  return union(
    [accountSettings.quoting?.defaultWorkflow],
    workflows.filter(f => workFlowModes[workflows.indexOf(f)]),
  )
}

export function useBuildFilterLists(baseFilterList: SavedFilter[]) {
  const workflows = usePreferredWorkflows()
  return [
    ...workflows.map(w =>
      baseFilterList.find(filter =>
        filter.name.toLowerCase().includes(w.toString()),
      ),
    ),
  ]
}
