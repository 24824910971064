import clsx from 'clsx'

import { ValidatedForm } from '@fv/client-components'
import { useFormModelState } from '@fv/client-core'

import { FormActions } from '../../components/forms/FormActions'
import { InputGroup } from '../../components/inputs/InputGroup'
import { SliderPanelLayout } from '../../components/SliderPanelLayout'
import { MarkupField } from './MarkupField'
import { useAddUser, useUpdateUser } from './mutations'
import { type AccountUserResponseDto, type UserFormModel } from './types'
import { UserPermissionFormField } from './UserPermissionFormField'

type Props = {
  existingUser?: AccountUserResponseDto
  onSaved?: () => void
  onCancel?: () => void
}

export const AccountUserForm = ({ existingUser, onSaved, onCancel }: Props) => {
  const {
    register,
    value: model,
    setValue: setModel,
  } = useFormModelState<UserFormModel>({
    initialValue: {
      email: existingUser?.username ?? '',
      markup: existingUser?.rate_modifier ?? 0,
      permissions: existingUser?.permissions ?? ['quote', 'book'],
    },
    schema: {
      markup: { valueAsNumber: true },
    },
  })
  const addUser = useAddUser()
  const updateUser = useUpdateUser()
  const handleSubmit = async () => {
    if (!existingUser) {
      try {
        await addUser.mutateAsync(model)
      } catch (e) {
        return
      }
    } else {
      await updateUser.mutateAsync({
        permissions: model.permissions,
        markup: model.markup,
        id: existingUser._id,
      })
    }
    onSaved?.()
  }

  return (
    <SliderPanelLayout
      title={existingUser ? 'Edit this user' : 'Add a user'}
      titleIcon="user"
    >
      <ValidatedForm onValidSubmit={handleSubmit}>
        <div className="mb-2 mt-4 flex gap-x-2">
          {existingUser && (
            <div className="basis-1/2">
              <InputGroup
                inputType="text"
                label="Name"
                inputProps={{
                  name: 'name',
                  value: `${existingUser.firstName} ${existingUser.lastName}`,
                  type: 'text',
                  disabled: true,
                  readOnly: true,
                }}
              />
            </div>
          )}
          <div className={clsx(existingUser ? 'basis-1/2' : 'basis-full')}>
            <InputGroup
              inputType="text"
              label="Email"
              inputProps={{
                ...register('email'),
                disabled: !!existingUser,
                readOnly: !!existingUser,
              }}
            />
          </div>
        </div>
        <div className="flex items-center ">
          <div className="flex basis-2/3 gap-x-4">
            <UserPermissionFormField
              value={model.permissions}
              onChange={permissions =>
                setModel(p => ({
                  ...p,
                  permissions,
                }))
              }
            />
          </div>
          <MarkupField
            className="my-0 ml-auto basis-1/3"
            value={model.markup}
            onChange={markup => setModel(p => ({ ...p, markup }))}
          />
        </div>
        <FormActions
          className="mt-8 border-t border-[#ccc] pt-4"
          loading={addUser.isLoading || updateUser.isLoading}
          submitText={existingUser ? 'Save changes' : 'Create user'}
          onCancel={onCancel}
        />
      </ValidatedForm>
    </SliderPanelLayout>
  )
}
