import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import uniq from 'lodash/uniq'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  flattenPages,
  getNextPageParam,
  type Page,
  useDebouncedValue,
} from '@fv/client-core'
import { type LegacyHandlingUnitDTO } from '@fv/client-types'

import { apiUri } from '../../constants'
import { staticQueryOptions } from '../../hooks/useStaticDataQuery'
import { removeUndefinedValues } from '../../utils/removeUndefinedValues'
import { shipperFetch } from '../../utils/shipperFetch'
import { buildProductCatalogEntry } from './commodityUtils'
import { type ProductCatalogFilter } from './useProductCatalog'

const MAX_RESULTS = 50

export const handlingUnitKeys = {
  all: ['products'] as const,
  id: (id: string) => ['handling-unit', id] as const,
  filter: (filter: Partial<ProductCatalogFilter>) =>
    filter.mode
      ? ([...handlingUnitKeys.all, filter] as const)
      : handlingUnitKeys.all,
  search: (filter: ProductCatalogFilter, text: string) =>
    [...handlingUnitKeys.filter(filter), text] as const,
}

async function fetchHandlingUnits(dto: {
  filter: ProductCatalogFilter
  searchText: string
  continuationToken?: string
}): Promise<Page<LegacyHandlingUnitDTO>> {
  const endpoint = `${apiUri}/handling-units`
  const options = buildFetchOptionsWithAuth({
    query: {
      limit: MAX_RESULTS,
      mode: dto.filter.mode,
      onlyContainable: dto.filter.onlyContainable,
      ...(dto.continuationToken && {
        continuationToken: dto.continuationToken,
      }),
      ...(dto.searchText && { searchText: dto.searchText }),
    },
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

async function fetchHandlingUnit(id: string): Promise<LegacyHandlingUnitDTO> {
  return shipperFetch(`/handling-units/${id}`)
}

// Used to fetch product catalog entries
export function useProducts(
  enabled: boolean,
  filter: ProductCatalogFilter,
  searchText: string,
) {
  const debouncedSearchText = useDebouncedValue(searchText.trim())

  const productsQuery = useInfiniteQuery(
    handlingUnitKeys.search(filter, debouncedSearchText),
    ({ pageParam = '' }) =>
      fetchHandlingUnits({
        filter,
        searchText: debouncedSearchText,
        continuationToken: pageParam,
      }),
    {
      enabled,
      getNextPageParam: p => p.hasMore && p.continuationToken,
      keepPreviousData: true,
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => ({
          ...page,
          data: page.data.map(u => buildProductCatalogEntry(filter.mode, u)),
        })),
      }),
    },
  )

  return {
    ...productsQuery,
    data: flattenPages(productsQuery.data?.pages),
  }
}

// Stable reference while loading or no data
const defaultData: LegacyHandlingUnitDTO[] = []

// Used to fetch handling units on a load
export function useHandlingUnits(
  load?: {
    items?: Array<{ handlingUnitId?: string }>
  } | null,
) {
  const queryClient = useQueryClient()
  const ids = removeUndefinedValues(
    (load?.items ?? []).map(i => i.handlingUnitId).filter(id => !!id),
  )

  const queries = useQueries({
    queries: uniq(ids).map(id => ({
      ...staticQueryOptions,
      queryFn: () => fetchHandlingUnit(id),
      queryKey: handlingUnitKeys.id(id),
      retry: false,
      onError: () => {
        queryClient.setQueryData(handlingUnitKeys.id(id), null)
      },
    })),
  })

  const data = removeUndefinedValues(queries.map(q => q.data).filter(d => !!d))
  const loading = queries.some(q => q.isLoading)

  return {
    data: loading || !data.length ? defaultData : data,
    loading,
  }
}

export const useProduct = (id?: string) => {
  return useQuery(
    handlingUnitKeys.id(id ?? ''),
    () => fetchHandlingUnit(id ?? ''),
    {
      enabled: !!id,
    },
  )
}
