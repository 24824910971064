import { type ChangeEvent, useEffect, useState } from 'react'

import { FvButton, Icon, SliderPanel } from '@fv/client-components'

import { supportMessage } from '../constants'
import { useExportList } from '../features/shipment-list/hooks/useExportList'
import { useAppContext } from '../hooks/settings'
import InfoBox from './InfoBox'
import { List, ListItem } from './List'

type ExportOptionsProps = {
  closePanel: () => void
  isOpen: boolean
  queryString: string
}

const ExportOptions = ({
  closePanel,
  isOpen,
  queryString,
}: ExportOptionsProps) => {
  const contextQuery = useAppContext()
  const email = contextQuery.data?.user.email
  const exportList = useExportList()

  const prevExportType = localStorage.getItem('fvExportType') || 'basic'

  const [exportType, setExportType] = useState(prevExportType)
  function updateAdvancedExport(e: ChangeEvent<HTMLInputElement>) {
    setExportType(e.target.value)
  }

  useEffect(() => {
    if (!isOpen) exportList.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  function exportShipmentList() {
    localStorage.setItem('fvExportType', exportType)
    if (exportList.isLoading) return
    exportList.mutate({
      queryString,
      advancedExport: exportType === 'advanced',
    })
  }

  return (
    <SliderPanel closePanel={closePanel} isOpen={isOpen}>
      <div className="flex items-center">
        <h6 className="mb-0">
          <Icon icon="ballot-check" className="text-fv-gray-dark" />
          <span>The export will be emailed to {email ?? 'you'}.</span>
        </h6>
      </div>
      <hr className="mb-8" />
      {!exportList.isSuccess && (
        <>
          <div className="radio">
            <input
              id="basic-export"
              aria-describedby="helper-radio-text"
              checked={exportType !== 'advanced'}
              value="basic"
              name="basicExport"
              type="radio"
              onChange={updateAdvancedExport}
              className="radio__input"
            />
            <label className="radio__display" htmlFor="basic-export" />
            <label className="radio__label" htmlFor="basic-export">
              Basic export
            </label>
          </div>

          <hr />
          <div className="flex items-start">
            <Icon
              icon="arrow-right"
              className="text-fv-gray"
              transform="down-2.5"
            />
            <p className="mb-0">
              Export shipment data as a comma separated value (.csv) file.
            </p>
          </div>

          <hr className="bg-fv-orange my-8" />

          <div className="radio">
            <input
              type="radio"
              checked={exportType === 'advanced'}
              name="advancedExport"
              value="advanced"
              onChange={updateAdvancedExport}
              id="advanced-export"
              aria-describedby="helper-radio-text"
              className="radio__input"
            />
            <label className="radio__display" htmlFor="advanced-export" />
            <label htmlFor="advanced-export" className="radio__label">
              Advanced export
            </label>
          </div>

          <hr />

          <List fcp0 className="mb-8">
            <ListItem itemsStart>
              <Icon
                icon="arrow-right"
                className="text-fv-gray"
                transform="down-2.5"
              />
              <p className="mb-0">
                Export shipment data as an Excel (.xlsx) file, with additional
                data:
              </p>
            </ListItem>
            <ListItem itemsStart dashed>
              <Icon
                icon="arrow-right"
                className="text-fv-gray"
                transform="down-2.5"
              />
              <p className="mb-0">
                Truckload route stops (location address, contact information,
                etc)
              </p>
            </ListItem>
            <ListItem itemsStart dashed>
              <Icon
                icon="arrow-right"
                className="text-fv-gray"
                transform="down-2.5"
              />
              <p className="mb-0">
                Shipping items (pallets, freight classes, weights, dimensions,
                etc)
              </p>
            </ListItem>
            <ListItem itemsStart dashed>
              <Icon
                icon="arrow-right"
                className="text-fv-gray"
                transform="down-2.5"
              />
              <p className="mb-0">
                Quotes (carrier, service, equipment, rate total)
              </p>
            </ListItem>
            <ListItem itemsStart dashed>
              <Icon
                icon="arrow-right"
                className="text-fv-gray"
                transform="down-2.5"
              />
              <p className="mb-0">
                {' '}
                Itemized charges (linehaul, fuel, accessorials, etc)
              </p>
            </ListItem>
            <ListItem itemsStart dashed>
              <Icon
                icon="arrow-right"
                className="text-fv-gray"
                transform="down-2.5"
              />
              <p className="mb-0"> Tender rejections (carrier)</p>
            </ListItem>
          </List>
          <hr />
        </>
      )}
      {/* <ul className="standard-list !mb-4">
        <li>
          <CheckboxField
            checked
            label="Export origin address"
            labelClassName="limit-characters"
            name="originAddress"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export origin contact information"
            labelClassName="limit-characters"
            name="originContact"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export origin accessorials"
            labelClassName="limit-characters"
            name="originAccessorials"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export destination address"
            labelClassName="limit-characters"
            name="destinationAddress"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export destination contact information"
            labelClassName="limit-characters"
            name="destinationContact"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export destination accessorials"
            labelClassName="limit-characters"
            name="destinationAccessorials"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export rate and carrier information"
            labelClassName="limit-characters"
            name="rateAndCarrier"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export collective freight information"
            labelClassName="limit-characters"
            name="freightInfo"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
        <li>
          <CheckboxField
            checked
            label="Export freight item information"
            labelClassName="limit-characters"
            name="itemInfo"
            onChange={e => {
              console.log({ checked: e.target.checked })
            }}
          />
        </li>
      </ul> */}

      {exportList.isSuccess ? (
        <>
          <div className="help-box boxed gradient">
            <Icon
              icon="check-circle"
              className="text-fv-green"
              transform="down-2"
            />
            <p className="!mb-0">
              Freightview is in the process of exporting a custom{' '}
              {exportType === 'advanced' ? '.xlsx' : 'csv'} file to your email.
              You can continue using Freightview. Your custom{' '}
              {exportType === 'advanced' ? '.xlsx' : 'csv'} file should be in
              your inbox within a few minutes.
            </p>
          </div>
          <FvButton icon="check" theme="default" onClick={closePanel}>
            <span>OK</span>
          </FvButton>
        </>
      ) : (
        <div className="flex items-center">
          <FvButton
            theme="primary"
            icon={exportList.isLoading ? 'spinner' : 'file-export'}
            onClick={exportShipmentList}
          >
            <span>Export</span>
          </FvButton>
          <FvButton
            theme="plain"
            icon="times"
            onClick={e => {
              closePanel()
            }}
          >
            <span>Cancel</span>
          </FvButton>
        </div>
      )}

      {exportList.isError && (
        <InfoBox icon="triangle">
          Unable to export list, {supportMessage}
        </InfoBox>
      )}
    </SliderPanel>
  )
}

export default ExportOptions
